import React, { Component } from 'react'
import { reaction, runInAction } from 'mobx'
import { observer, inject } from 'mobx-react'
import {
  Alert,
  Container,
  Row,
  Col
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'

/* components */
import Header from '../../components/Header'
import {
  SignUpModal,
  PremiumModal,
  FreeSlotModal,
  AudioMessageModal
} from '../../components/Modal'
import AudioElement from '../../components/Audio'
import Upcoming from '../../components/Upcoming'
import Channels from '../../components/Channels'
import PlayerHeader from '../../components/PlayerHeader'
import PlayerControls from '../../components/PlayerControls'
import RecentlyAdded from '../../components/RecentlyAdded'
import EmailVerification from '../../components/EmailVerification'
import Promotion from '../../components/Promotion'
import {
  Close
} from '../../components/Icons'

/* css */
import 'react-toastify/dist/ReactToastify.css'
import './Player.css'

/* other */
import { isIOS } from '../../helpers/mobile-detect'
import Analytics from '../../Analytics'
const analytics = new Analytics()

class Player extends Component {
  audio = {}
  shouldRefreshPlaylist = true
  shouldRefreshAudioMessages = true
  playlistDisposer
  volumeDisposer
  audioMessageDisposer

  state = {
    playing: false,
    // Is necessary to load player after the first fetch requests is done
    // Otherwise it will fail on cors error
    // Explanation: https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
    loadPlayer: false,
    volume: 50,
    showSignUpModal: false,
    showPremiumModal: false,
    showFreeSlotModal: false
  }

  async componentDidMount () {
    this.playlistDisposer = reaction(
      () => ({
        id: this.props.userStore.id,
        isPremium: this.props.userStore.isPremium,
        disableMotivationQuotes: this.props.userStore.disableMotivationQuotes,
        explicitFilter: this.props.userStore.explicitFilter,
        businessMessages: this.props
          .audioMessageStore
          .audioMessages
          .map((audioMessage) => `${audioMessage.id}${audioMessage.enabled}`)
      }),
      () => this.shouldRefreshPlaylist = true
    )

    this.audioMessageDisposer = reaction(
      () => this.props.userStore.id,
      () => this.shouldRefreshAudioMessages = true
    )

    this.volumeDisposer = reaction(
      () => this.props.playerStore.normalizedVolume,
      (normalizedVolume) => {
        const { playerStore } = this.props
        const { volumeChangeInProgress, volume } = playerStore
        if (!volumeChangeInProgress) {
          this.setState({
            volume
          })
        }
        this.setAudioTagVolume(normalizedVolume)
      },
      { fireImmediately: true }
    )
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.shouldRefreshAudioMessages) {
      const { audioMessageStore } = this.props
      audioMessageStore.loadAudioMessages()
      this.shouldRefreshAudioMessages = false
    }
    if (this.shouldRefreshPlaylist) {
      this.shouldRefreshPlaylist = false
      // Call refresh tracks in the next tick
      setTimeout(() => { this.refreshTracks() }, 500)
    }
  }

  componentWillUnmount () {
    runInAction(() => {
      this.playlistDisposer()
      this.audioMessageDisposer()
      this.volumeDisposer()
    })
  }

  setAudioTagVolume (normalizedVolume: number) {
    if (this.audio && this.audio.audioEl) {
      this.audio.audioEl.volume = normalizedVolume
    }
  }

  async nextTrack (channel) {
    const {
      playlistStore
    } = this.props
    await playlistStore.nextTrack(channel.tags)
  }

  freeSlotUntil = -1
  async checkFreeSlot (channel) {
    const { userStore, playerStore } = this.props
    const { limitFreeStation, isPremium } = userStore
    const { playing, showFreeSlotModal } = playerStore

    const shouldCheck = limitFreeStation && !isPremium && playing && !showFreeSlotModal
    if (shouldCheck) {
      const now = Date.now()
      const isFreeLimitExpired = this.freeSlotUntil > 0 && now > this.freeSlotUntil

      if (isFreeLimitExpired) {
        this.showFreeSlotModal()
        analytics.track({
          event: 'free_slot_popup_showed'
        })
        this.pause(true)
        this.nextTrack(channel)
        this.setFreeSlotUntil()
        throw new Error('No free slots left')
      }
    }
  }

  setFreeSlotUntil () {
    const minutes = 20
    const expiresIn = minutes * 60 * 1000
    this.freeSlotUntil = Date.now() + expiresIn
  }

  async playPause () {
    try {
      const { playerStore, userStore } = this.props
      const { normalizedVolume, playing } = playerStore
      const { limitFreeStation, isPremium } = userStore
      const { loadPlayer } = this.state
      if (!playing) {
        await this.forceLogin()
      }

      if (!loadPlayer) {
        this.setState({
          loadPlayer: true
        })
        this.setAudioTagVolume(normalizedVolume)
      }

      if (playing) {
        this.pause()
      } else {
        this.play()
        const shouldLimit = limitFreeStation && !isPremium
        if (shouldLimit) {
          this.setFreeSlotUntil()
        }
      }
    } catch (err) {}
  }

  async pause (offline = false) {
    if (this.audio && this.audio.audioEl) {
      const currentTime = this.audio.audioEl.currentTime
      this.audio.audioEl.pause()
      if (!offline) {
        this.audio.audioEl.load()
      }
      this.audio.audioEl.currentTime = currentTime
      const { playlistStore, playerStore } = this.props
      playerStore.playing = false

      const { stationStore } = this.props
      const { selectedStation } = stationStore
      const { currentTrack } = playlistStore
      analytics.track({
        event: 'song_paused',
        songName: currentTrack.fullName,
        songUuid: currentTrack.uuid,
        selectedPlaylistId: selectedStation.id
      })
    }
  }

  async play () {
    if (this.audio && this.audio.audioEl) {
      this.audio.audioEl.play()
      const { playerStore } = this.props
      playerStore.playing = true
    }
  }

  async skip (channel) {
    try {
      await this.forceLogin()
      await this.forcePremium()

      const { playerStore, playlistStore } = this.props

      console.log("SKIPPED")

      playerStore.skip(channel.tags)

      const { currentTrack } = playlistStore
      analytics.track({
        event: 'song_skipped',
        songName: currentTrack.fullName || '',
        songUuid: currentTrack.uuid,
        selectedPlaylistId: channel.id
      })
    } catch (err) {}
  }

  async dislike (channel, song) {
    try {
      await this.forceLogin()
      await this.forcePremium()

      const { playlistStore } = this.props
      await playlistStore.dislikeSong(song.uuid)

      analytics.track({
        event: 'song_disliked',
        songName: song.fullName || '',
        songUuid: song.uuid,
        selectedPlaylistId: channel.id
      })

      this.showDislikeSongToast()
    } catch (err) {}
  }

  async forceLogin () {
    const { userStore } = this.props
    const { isAuthenticated } = userStore
    if (!isAuthenticated) {
      this.setState({
        showSignUpModal: true
      })
      analytics.track({ event: 'login_popup_showed' })
      throw new Error('Not registered')
    }
  }

  async forcePremium () {
    const { userStore } = this.props
    const { isPremium } = userStore
    if (!isPremium) {
      this.showPremiumModal()
      analytics.track({ event: 'pro_popup_showed' })
      throw new Error('Not premium')
    }
  }

  async showFreeSlotModal () {
    this.setState({
      showFreeSlotModal: true
    })
  }

  async showPremiumModal () {
    this.setState({
      showPremiumModal: true
    })
  }

  async showAudioMessageModal () {
    this.setState({
      showAudioMessageModal: true
    })
  }

  async showChangedPlaylistToast () {
    const { stationStore } = this.props
    const { selectedStation } = stationStore
    const Message = ({ closeToast }) => (
      <Alert color="info" >
        <p>The playlist will change once the current song ends.
        <br />
        If you wish to change the playlist immediately, use the <a style={{ color: "#ff0046" }}  onClick={ () => { this.skip(selectedStation) } }>skip button</a>.</p>
        <span onClick={ closeToast }>
          <Close />
        </span>
      </Alert>
    )

    toast.info(<Message />)
  }

  async showDislikeSongToast () {
    const Message = ({ closeToast }) => (
      <Alert color="info">
        <p>Ok, we will never play this song at your gym again.</p>
        <span onClick={ closeToast }>
          <Close />
        </span>
      </Alert>
    )

    toast.info(<Message />)
  }

  async showNoConnectionToast () {
    const { stationStore } = this.props
    const { selectedStation } = stationStore
    const Message = ({ closeToast }) => (
      <Alert color="info">
        <p>No internet connection.<br />Make sure Wi-Fi or cellular data is turned on, then <a className="alert-link" onClick={ () => { this.play(selectedStation) } }>try again.</a></p>
        <span onClick={ closeToast }>
          <Close />
        </span>
      </Alert>
    )

    toast.info(<Message />)
  }

  setVolume (volume) {
    const { playerStore } = this.props
    playerStore.volume = volume
  }

  loadPlaylistPromise
  async refreshTracks () {
    const { playlistStore, playerStore, stationStore } = this.props
    const { playing } = playerStore
    const { selectedStation } = stationStore
    this.fetchRecentlyAdded(selectedStation.tags)

    try {
      if (this.loadPlaylistPromise) {
        this.loadPlaylistPromise.catch(() => {}) // Catch FLOW_CANCELED exception
        this.loadPlaylistPromise.cancel()
      }
      this.loadPlaylistPromise = playlistStore.loadPlaylist(selectedStation.tags, playing)
    } catch (err) {}
  }

  async removeTrack (channel, track) {
    try {
      await this.forceLogin()
      await this.forcePremium()
      const { playlistStore } = this.props
      playlistStore.removeTrack(track, channel.tags)

      analytics.track({
        event: 'song_removed',
        songName: track.fullName || '',
        songUuid: track.uuid,
        selectedPlaylistId: channel.id
      })
    } catch (err) {}
  }

  async onRequestQuotes () {
    try {
      await this.forceLogin()
      await this.forcePremium()
      /*const { email } = this.state
      const newWindow = window.open(`https://gymradio.typeform.com/to/lve1Gk?email=${email}`, '_blank')
      newWindow.focus()*/
      this.showAudioMessageModal()
      analytics.track({
        event: 'audio_promotions_popup_showed'
      })
    } catch (err) {}
  }

  async onDownloadedClick () {
    try {
      await this.forceLogin()
      await this.forcePremium()
    } catch (err) {
      this.showPremiumModal()
    }
  }

  async onExplicitFilterChange (option) {
    try {
      await this.forceLogin()
      await this.forcePremium()
      const { userStore } = this.props
      const value = option && option.value
      if (value) {
        userStore.setExplicitFilter(value)
        analytics.track({
          event: 'explicit_filter_changed',
          explicitFilterValue: value
        })
      }
    } catch (err) {}
  }

  async onRecentyAddedSelect (track) {
    try {
      await this.forceLogin()
      await this.forcePremium()
      const { playlistStore } = this.props
      playlistStore.addTrackByUuid(track.uuid, true)

      analytics.track({
        event: 'recenty_added_selected',
        audioMessageName: track.fullName,
        audioMessageUuid: track.uuid
      })
    } catch (err) {}
  }

  async setActiveStation (station) {
    this.props.stationStore.selectedStationId = station.id
    window.Intercom && window.Intercom('update', { selected_playlist_id: station.id })
  }

  async setActiveChannelRequest (channel) {
    const { playerStore } = this.props
    const { playing } = playerStore
    try {
      await this.forceLogin()
      await this.forcePremium()
      if (playing) {
        await this.showChangedPlaylistToast()
      }
      this.setActiveStation(channel)
      this.fetchRecentlyAdded(channel.tags)
      this.refreshTracks(channel.tags)

      analytics.track({
        event: 'playlist_selected',
        playlistId: channel.id
      })
    } catch (err) {}
  }

  async fetchRecentlyAdded (tags) {
    const { recentSongStore } = this.props
    recentSongStore.loadRecentSongs(tags)
  }

  // Audio callbacks
  async onEnded (channel) {
    try {
      await this.checkFreeSlot(channel)
      this.nextTrack(channel)
    } catch (err) {}
  }

  onError (status, text, channel) {
    const { playlistStore, stationStore } = this.props
    const { selectedStation } = stationStore
    const currentTrack = playlistStore.currentTrack
    analytics.track({
      event: 'playback_error',
      songName: currentTrack.fullName,
      songUuid: currentTrack.uuid,
      selectedPlaylistId: selectedStation.id,
      errorStatus: status,
      errorText: text
    })

    // Network error
    if (status === 0) {
      const { playerStore } = this.props
      const { playing } = playerStore
      if (playing) {
        this.pause(true)
        this.showNoConnectionToast()
      }
    // 3xx, 4xx, 5xx errors
    } else if (status >= 300) {
      this.nextTrack(channel)
    }
  }

  onPlay () {
    // console.log('play')
    /*this.setState({
      playing: true
    })*/
    const { playlistStore, stationStore, userStore, api } = this.props
    const { currentTrack } = playlistStore
    const { gaiaToken } = userStore
    const { selectedStation } = stationStore
    analytics.track({
      event: 'song_played',
      songName: currentTrack.fullName,
      songUuid: currentTrack.uuid,
      selectedPlaylistId: selectedStation.id
    })

    api.gaiaReport(gaiaToken, currentTrack.gaiaId)
  }

  onPause () {
    // console.log('pause')
    /*this.setState({
      playing: false
    })*/
  }

  onMute () {
    this.setVolume(0)
  }

  onLoud () {

  }

  // Modal
  onCancel () {
    this.setState({
      showSignUpModal: false,
      showPremiumModal: false,
      showFreeSlotModal: false,
      showAudioMessageModal: false
    })
  }

  // Audio messages
  onMotivationalMessagesStatusChanged (enabled: boolean) {
    const { userStore } = this.props
    userStore.setEnableMotivationQuotes(enabled)
    analytics.track({
      event: 'motivational_messages_status_changed',
      enabled
    })
  }

  onPromotionalMessagesStatusChanged (audioMessage: any, enabled: boolean) {
    audioMessage.setEnabled(enabled)
    analytics.track({
      event: 'promotional_messages_status_changed',
      promotionalMessageTitle: audioMessage.title,
      promotionalMessageUuid: audioMessage.uuid,
      enabled
    })
  }

  /* redirects */
  goToRecurly () {
    analytics.track({ event: 'get_pro_button_clicked' })
    this.props.userStore.redirectToPricingPage()
  }

  render () {
    const {
      audioMessageStore,
      userStore,
      playerStore,
      playlistStore,
      recentSongStore,
      stationStore
    } = this.props

    const {
      audioMessages
    } = audioMessageStore

    const {
      recentSongs
    } = recentSongStore

    const {
      playing
    } = playerStore

    const {
      isPremium,
      isAuthenticated,
      gymName,
      email,
      explicitFilter, // Must stay here as dependency for mobx. Otherwise It will not reload playlist after explicit filter change
      isEmailVerified,
      playlists: customChannels
    } = userStore

    const {
      stations,
      selectedStation
    } = stationStore

    const {
      currentTrack
    } = playlistStore
    const { title, artist, url } = currentTrack

    const {
      volume,
      loadPlayer,
      showSignUpModal,
      showFreeSlotModal,
      showPremiumModal,
      showAudioMessageModal,
    } = this.state

    const autoPlay = playing
    const shouldShowEmailVerificationNotification = isAuthenticated && !isEmailVerified

    return (
      <div>
        {
          shouldShowEmailVerificationNotification && (
            <EmailVerification
              email={ email }
            />
          )
        }
        <Header
          getPro={ () => {
            analytics.track({ event: 'get_pro_button_clicked' })
            this.showPremiumModal()
          } }
          showPricing={ () => {
            this.props.userStore.redirectToPricingPage()
          } }
          volume={ volume }
          onVolumeChange={ this.setVolume.bind(this) }
          onExplicitFilterChange={ this.onExplicitFilterChange.bind(this) }
          onMute={ this.onMute.bind(this) }
          onLoud={ this.onLoud }
        />
        <SignUpModal
          isOpen={ showSignUpModal }
          onCancel={ this.onCancel.bind(this) }
        />
        <FreeSlotModal
          isOpen={ showFreeSlotModal }
          onSubmit={ this.goToRecurly.bind(this) }
          onCancel={ this.onCancel.bind(this) }
        />
        <PremiumModal
          isOpen={ showPremiumModal }
          onSubmit={ this.goToRecurly.bind(this) }
          onCancel={ this.onCancel.bind(this) }
        />
        <AudioMessageModal
          isOpen={ showAudioMessageModal }
          onMotivationalMessagesStatusChanged={ this.onMotivationalMessagesStatusChanged.bind(this) }
          onPromotionalMessagesStatusChanged={ this.onPromotionalMessagesStatusChanged.bind(this) }
          quotes={ audioMessages }
          onCancel={ this.onCancel.bind(this) }
        />
        <ToastContainer
          position="bottom-center"
          autoClose={ 8000 }
          hideProgressBar
          newestOnTop
          closeOnClick
          closeButton={ false }
          rtl={ false }
          pauseOnVisibilityChange
          pauseOnHover
        />
        <Container
          fluid={ true }
        >
          <Row>
            <Col
              lg={{
                size: 4,
                offset: 0,
                order: "first"
              }}
              xs={{
                order: "last",
                size: 16,
                offset: 0
              }}
              className="stationbar"
            >
              <Channels
                channels={ stations }
                playing={ playing }
                activeChannelId={ selectedStation.id }
                onSelect={ (channel) => { this.setActiveChannelRequest(channel) } }
              />
            </Col>
            <Col
              lg={{
                size: 7,
                offset: 0
              }}
              xs={{
                size: 16,
                offset: 0
              }}
              className="main"
            >
              <PlayerControls
                playing={ playing }
                onSkip={ () => { this.skip(selectedStation) }}
                onDislike={ () => {
                  this.dislike(selectedStation, currentTrack)
                  this.skip(selectedStation)
                } }
                onPlayPause={ () => { this.playPause(selectedStation) } }
              />
              <PlayerHeader
                title={ title }
                artist={ artist }
              />
              <Upcoming
                onRemoveTrack={ (track) => { this.removeTrack(selectedStation, track) } }
                onNextTrack={ () => { this.skip(selectedStation) }}
                onDownloadedClick={ () => { this.onDownloadedClick() }}
                onDislikeTrack={ (track) => {
                  this.dislike(selectedStation, track)
                  if (track.uuid === currentTrack.uuid) {
                    this.skip(selectedStation)
                  } else {
                    this.removeTrack(selectedStation, track)
                  }
                }}
              />
              { loadPlayer &&
                (
                  <AudioElement
                    ref={(ref) => {  this.audio = ref } }
                    src={ url }
                    autoPlay={ autoPlay }
                    onError={ (status, text) => { this.onError(status, text, selectedStation) } }
                    onEnded={ () => { this.onEnded(selectedStation) } }
                    onPlay={ () => { this.onPlay(selectedStation) } }
                    onPause={ () => { this.onPause(selectedStation) } }
                  />
                )
              }
            </Col>
            <Col
              lg={{
                size: 5,
                offset: 0
              }}
              xs={{
                size: 16,
                offset: 0
              }}
              className="sidebar"
            >
              {
                audioMessages && audioMessages.length > 0 &&
                (
                  <div className="widget">
                    <Promotion
                      onOpen={ this.onRequestQuotes.bind(this) }
                    />
                  </div>
                )
              }
              
              {
                recentSongs && (
                  <div className="widget">
                    <RecentlyAdded
                      recentlyAdded={ recentSongs }
                      channelName={ selectedStation.name }
                      onSelect={ this.onRecentyAddedSelect.bind(this) }
                    />
                  </div>
                )
              }
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default inject((stores) => ({
  playlistStore: stores.playlistStore,
  stationStore: stores.stationStore,
  playerStore: stores.playerStore,
  userStore: stores.userStore,
  trackPreloaderStore: stores.trackPreloaderStore,
  recentSongStore: stores.recentSongStore,
  audioMessageStore: stores.audioMessageStore,
  api: stores.api
}))(
  observer(Player)
)

