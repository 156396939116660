import React, { Component } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'
import { create } from 'mobx-persist'

import AppContainer from './containers/App'
import PlayerContainer from './containers/Player'
import Loading from './components/Loading'
import Api from './Api'
import history from './history'
import Analytics from './Analytics'
import AudioMessageStore from './stores/AudioMessage'
import UserStore from './stores/User'
import PlaylistStore from './stores/Playlist'
import TrackPreloaderStore from './stores/TrackPreloader'
import RecentSongStore from './stores/RecentSong'
import PlayerStore from './stores/Player'
import FranchiseStore from './stores/Franchise'
import StationStore from './stores/Station'

const analytics = new Analytics()
const api = new Api()
const franchiseStore = new FranchiseStore(api)
const userStore = new UserStore(franchiseStore)
const stationStore = new StationStore(userStore)
const audioMessageStore = new AudioMessageStore(api, userStore)
const playlistStore = new PlaylistStore(api, userStore, audioMessageStore)
const trackPreloaderStore = new TrackPreloaderStore(userStore, playlistStore)
const recentSongStore = new RecentSongStore(api, playlistStore)
const playerStore = new PlayerStore(playlistStore)

// Persist stores
const hydrate = create({})
const trackPreloadedHydrated = hydrate('track_preloader', trackPreloaderStore, {})
const playerHydrated = hydrate('player', playerStore, {})
const stationHydrated = hydrate('station', stationStore, {})
const userHydrated = hydrate('user', userStore, {})

const handleAuthentication = async ({ location, match }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    try {
      await userStore.handleAuthentication()
    } catch (err) {
      console.error(err)
    }

    const redirectUrl = userStore.redirectUrl

    userStore.resetRedirectUrl()
    history.replace(redirectUrl)
  }
  return
}

const DefaultLayout = ({ component: Component, action, ...rest }) => {
  return (
    <Route
      { ...rest }
      render={
        (matchProps) => {
          return (
            <AppContainer>
              <Component { ...matchProps } />
            </AppContainer>
          )
        }
      }
    />
  )
}

class App extends Component {
  state = {
    storesHydrated: false
  }

  componentDidMount () {
    Promise
      .all([
        trackPreloadedHydrated,
        playerHydrated,
        userHydrated,
        stationHydrated
      ])
      .then(() => {
        userStore.renewToken(() => {
          this.setState({
            storesHydrated: true
          })

          const {
            id: userId,
            email,
            franchiseId
          } = userStore

          analytics.init(userId, franchiseId, email)
        })
      })
  }


  render () {
    if (!this.state.storesHydrated) {
      return <Loading />
    }
    return (
      <Provider 
        userStore={ userStore }
        stationStore={ stationStore }
        audioMessageStore={ audioMessageStore }
        playlistStore={ playlistStore }
        recentSongStore={ recentSongStore }
        trackPreloaderStore={ trackPreloaderStore }
        playerStore={ playerStore }
        franchiseStore={ franchiseStore }
        api={ api }
      >
      <Router history={ history }>
        <Switch>
          <DefaultLayout exact path="/" component={ PlayerContainer } />
          <Route path="/callback" render={ (props) => {
            handleAuthentication(props)
            
            analytics.track({ event: 'logged_in' })
            return <Loading { ...props } /> 
          }}/>
          <Route path="/franchise/:franchiseId" render={ (props) => {
            const franchiseId = props.match.params.franchiseId
            if (!userStore.isAuthenticated) {
              userStore.login('', true, franchiseId)
            } else {
              history.replace('/')
            }
            return <Loading { ...props } /> 
          }}/>
          <Route path="/subscribe/:planId" render={(props) => {
            const planId = props.match.params.planId
            // const redirectUrl = props.match.url

            if (!userStore.isAuthenticated) {
              userStore.login()
            } else {
              userStore.redirectToRecurly(planId, false)
            }
            return <Loading { ...props } />
          }}/>
          <Route path="/verify-email/success" render={(props) => {
            history.replace('/')
            analytics.track({ event: 'email_verified' })
            return <Loading {...props} /> 
          }}/>
          <Route path="/signup/success" render={(props) => {
            if (userStore.isAuthenticated) {
              userStore.forcePaid()
            }
            analytics.track({ event: 'pro_activated' })
            history.replace('/')
            return <Loading {...props} /> 
          }}/>
        </Switch>
      </Router>
      </Provider>
    )
  }
}

export default observer(App)
