/* @flow */
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Select from "react-select";

import Volume from "../Volume";
import { Desktop, Mobile, TabletAndUp } from "../Responsive";
import ProButton from "./ProButton";

import "./Header.css";
import { Logo, Logout } from "../Icons";

export type OwnProps = {
  isPremium: boolean,
  isAuthenticated: boolean,
  isTrial: boolean,
  gymName: string,
  premiumUntil: number,
  volume: number,
};

export type DispatchProps = {
  login: () => void,
  logout: () => void,
  getPro: () => void,
  showPricing: () => void,
  onVolumeChange: (volume: number) => void,
  onMute: () => void,
  onLoud: () => void,
};

export type Props = OwnProps & DispatchProps;

const explicitOptions = [
  { value: "clean", label: "No explicits" },
  { value: "soft", label: "Soft explicits" },
  { value: "explicit", label: "Explicits" },
];

class Header extends Component<Props, { isOpen: boolean }> {
  state = {
    isOpen: false,
    dropDownOpen: false,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.login = this.login.bind(this);
    this.signUp = this.signUp.bind(this);
    this.logout = this.logout.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  login() {
    const { userStore } = this.props;
    userStore.login();
  }

  signUp() {
    const { userStore } = this.props;
    userStore.signUp();
  }

  logout() {
    const { userStore } = this.props;
    userStore.logout();
  }

  toggleDropdown() {
    console.log("toggled DropDown");
    this.setState({ dropDownOpen: !this.state.dropDownOpen });
  }

  componentDidMount() {
    const { userStore } = this.props;
    console.log(userStore.email);
    console.log(userStore.gymName);
  }

  render() {
    const {
      volume,
      onLoud,
      onMute,
      onVolumeChange,
      onExplicitFilterChange,
      getPro,
      showPricing,
      userStore,
    } = this.props;

    const {
      isPremium,
      isSubscribed,
      isTrial,
      isAuthenticated,
      explicitFilter,
      gymName,
      premiumUntil,
      franchise,
      email,
    } = userStore;

    const showGetPro = isAuthenticated;
    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/" style={{ padding: "0 2vw 0 2vw" }}>
            <img src="/assets/images/logo.png" width={120} />
          </NavbarBrand>
          <TabletAndUp>
            <div className="header__separator">
              <div className="header__separator-content" />
            </div>
          </TabletAndUp>
          {showGetPro && (
            <TabletAndUp>
              <ProButton
                isPremium={isPremium}
                isTrial={isTrial}
                getPro={getPro}
                gymName={gymName}
                franchiseName={
                  franchise && franchise.id !== "gymradio" && franchise.name
                }
                premiumUntil={premiumUntil}
              />
            </TabletAndUp>
          )}
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {isAuthenticated && (
                <div
                  className="header__section"
                  style={{ position: "relative", zIndex: "100" }}
                >
                  <Select
                    value={explicitOptions.find((option) => {
                      return option.value === explicitFilter;
                    })}
                    isSearchable={false}
                    classNamePrefix="selectbox"
                    className="selectbox"
                    onChange={onExplicitFilterChange}
                    options={explicitOptions}
                  />
                </div>
              )}
              {isAuthenticated && (
                <Desktop>
                  <div className="header__volume header__section">
                    <Volume
                      step={10}
                      defaultValue={volume}
                      value={volume}
                      onChange={onVolumeChange}
                      onMute={onMute}
                      onLoud={onLoud}
                    />
                  </div>
                </Desktop>
              )}
              {isAuthenticated && !isSubscribed && (
                <NavItem className="header__section">
                  <NavLink onClick={showPricing}>Pricing</NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem className="header__section header__section--logout">
                  <Dropdown
                    isOpen={this.state.dropDownOpen}
                    toggle={this.toggleDropdown}
                  >
                    <DropdownToggle
                      style={{
                        backgroundColor: "white",
                        color: this.state.dropDownOpen ? "red" : "black",
                        border: this.state.dropDownOpen
                          ? "1px solid red"
                          : "1px solid black",
                        boxShadow: "none",
                      }}
                      caret
                    >
                      {email.split("@")[0]} 
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavLink onClick={this.logout}>
                          Logout{` `}
                          <Logout />
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
              )}
              {!isAuthenticated && (
                <NavItem className="header__section">
                  <NavLink onClick={this.login}>Login</NavLink>
                </NavItem>
              )}
              {!isAuthenticated && (
                <NavItem className="header__section">
                  <NavLink onClick={this.signUp}>Sign up</NavLink>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default inject((stores) => ({
  userStore: stores.userStore,
}))(observer(Header));
